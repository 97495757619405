#cssload-loader-wrapper {
  width: 100%;
  background-color: white;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0.7;
  &.hideLoader {
    display: none;
  }
}

#cssload-loader {
  width: 244px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 18px;
  color: rgb(0, 0, 0);
  letter-spacing: 0.2em;
  &.hideLoader {
    display: none;
  }
}
#cssload-loader::before,
#cssload-loader::after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background: rgb(232, 207, 46);
  position: absolute;
  animation: cssload-load 0.665s infinite alternate ease-in-out;
  -o-animation: cssload-load 0.665s infinite alternate ease-in-out;
  -ms-animation: cssload-load 0.665s infinite alternate ease-in-out;
  -webkit-animation: cssload-load 0.665s infinite alternate ease-in-out;
  -moz-animation: cssload-load 0.665s infinite alternate ease-in-out;
}
#cssload-loader::before {
  top: 0;
}
#cssload-loader::after {
  bottom: 0;
}

/* ### BEGIN - ANIMATIONS ### */
@keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 39px;
  }
  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

@-o-keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 39px;
  }
  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

@-ms-keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 39px;
  }
  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

@-webkit-keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 39px;
  }
  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

@-moz-keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 39px;
  }
  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

/* ### END - ANIMATIONS ### */
