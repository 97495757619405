@import '../../../node_modules/bulma/sass/utilities/_all.sass';

.AdminDashboardSectionComponent {
  &.container {
    @include mobile() {
      .SpacerComponent {
        height: unset;
      }

      .box {
        padding: unset;
      }
    }
  }
}
