/* inspired by: https://css-tricks.com/responsive-data-tables/ */

.responsiveTable {
  width: 100%;
  // border-collapse: separate;
  border-spacing: 0 0.5em;

  thead tr {
    border-bottom: 2px solid #5f5f5f;

    th {
      padding-left: 0.5rem;
    }
  }

  tbody tr {
    border-bottom: 2px solid #e2e2e2;
    &:nth-child(even) {
      background-color: #d6d6d6;
    }
  }

  td {
    padding-left: 0.5rem;
    vertical-align: middle;
  }

  td .tdBefore {
    display: none;
  }
}

@media screen and (max-width: 40em) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    // border-bottom: 2px solid #5f5f5f;
  }

  .responsiveTable tbody tr {
    // border: 1px solid #000;
    border-bottom: 2px solid #e2e2e2;
    padding: {
      top: 0.7rem;
      bottom: 0.7rem;
      left: 0.25rem;
      right: 0.8rem;
    }
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
  }
}
