.Bet365Component.iframe-container {
  position: relative;
  height: 519px;
  overflow: hidden;
  width: 100%;
  max-width: 120px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 600px;
  }
}
