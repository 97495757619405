.ScoreCardSectionComponent {

  .DropdownComponent {
    margin-right: 6px; 
  }

  .w-100-p {
    width: 100%;
  }

  .flex-justified-center {
    justify-content: center;
  }
  
}