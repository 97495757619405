.FooterComponent.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px; /* Footer height */
  padding: 6rem 6rem;

  display: flex;
  justify-content: center;
  align-items: center;

  .footer__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer__inner img {
    // padding-bottom: 24px;
  }
  
  @media screen and (min-width: 769px) {
    .footer__inner {
      max-width: 769;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
  
    .footer__inner img {
      padding-bottom: unset;
    }
  }
}
